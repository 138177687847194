import { useMemberQuery } from "@/hooks/use-member-query";
import type { VariantProps } from "class-variance-authority";
import { Fragment, useMemo } from "react";
import { Member } from "@/types/entities";
import { Skeleton } from "./ui/skeleton";
import Avatar, { AvatarComponentProps } from "./ui/avatar";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const m = cva([""], {
  variants: {
    height: {
      15: "h-[15px]"
    },
    width: {
      15: "w-[15px]",
      75: "w-[75px]"
    }
  },
  defaultVariants: {
    height: 15,
    width: 75
  }
});

export const MemberEmail = ({ memberId, height = 15 }: { memberId: string; height?: number }) => {
  const { data: member, status } = useMemberQuery({ memberId });

  return <Fragment />;

  // if (status === "pending")
  //   return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
  // if (status === "error") return "-";
  // return <Fragment>{member?.email}</Fragment>;
};

export const MemberInfoByKey = ({
  memberId,
  height = 15,
  infoKey
}: {
  memberId: string;
  height?: number;
  infoKey: keyof Member;
}) => {
  const { data: member, status } = useMemberQuery({ memberId });

  if (status === "pending")
    return (
      <Skeleton
        // component="span"
        className="block w-[75px]"
        // height={height}
        // radius="xl"
      />
    );
  if (status === "error") return "-";
  return member?.[infoKey];
};

type MemberNameProps = {
  memberId: string;
  height?: number;
  ownDenote?: string;
  additionalInfo?: boolean;
  loaderClassName?: string;
  memberData?: Member;
} & VariantProps<typeof m>;

export const MemberName = ({
  memberId,
  height,
  width,
  ownDenote,
  additionalInfo = false,
  loaderClassName,
  memberData
}: MemberNameProps) => {
  const { data: member, status } = useMemberQuery({
    skip: memberData ? true : false,
    data: memberData,
    memberId: memberId
  });
  const { data: profile, status: s } = useMemberQuery({ memberId: "me" });

  if (status === "pending" || s === "pending")
    return <Skeleton className={cn(m({ height, width }), loaderClassName)} />;
  if (status === "error") return "-";
  if (ownDenote && member && profile?.id === member.id) {
    return <Fragment>{ownDenote}</Fragment>;
  }
  return (
    <Fragment>
      {member && (
        <>
          {member.displayName && typeof member?.displayName === "string" ? member.displayName : <>{member.firstName} {member.lastName}</>}
        </>
      )}
      {additionalInfo && (
        <span className="ml-2 text-sm text-gray-300">{member && member.pronunciation}</span>
      )}
    </Fragment>
  );
};

export const MemberUsername = ({
  memberId,
  height = 15
}: {
  memberId: string;
  height?: number;
}) => {
  const { data: member, status } = useMemberQuery({ memberId });

  if (status === "pending") return <Skeleton className="block w-[75px]" />;
  if (status === "error") return "-";

  return <Fragment>{member && member.username}</Fragment>;
};

const memberImg = cva(["rounded-full"], {
  variants: {
    size: {
      16: "h-4 w-4",
      20: "h-5 w-5",
      24: "h-6 w-6",
      32: "h-8 w-8",
      40: "h-10 w-10",
      56: "h-[56px] w-[56px]",
      72: "h-[72px] w-[72px]",
      96: "h-24 w-24"
    }
  },
  defaultVariants: {
    size: 20
  }
});

type MemberImageProps = {
  memberId: string;
  indicator?: any;
  avatar?: Partial<Omit<AvatarComponentProps, "variant">>;
} & VariantProps<typeof memberImg>;

export const MemberImage = ({ memberId, avatar }: MemberImageProps) => {
  const { data: member, status } = useMemberQuery({ memberId });

  // const _indicator = useMemo(() => {
  //   if (typeof indicator === "undefined") return true;
  //   return (
  //     !indicator.disabled && typeof member?.isOnline !== "undefined" && member.isOnline !== true
  //   );
  // }, [indicator, member]);

  // return <Avatar variant="standard" size={40} {...props}></Avatar>;

  const name = useMemo(() => {
    if (member) {
      return `${String(member.firstName).charAt(0).toUpperCase()}${String(member.lastName).charAt(0).toUpperCase()}`;
    }

    return "-";
  }, [member]);

  if (status === "pending" || status === "error") {
    return <Skeleton className={cn(memberImg({ size: avatar?.size }))} />;
  }
  if (member) {
    return (
      <Avatar
        imgSrc={member?.optimized_image?.webp ? member?.optimized_image?.webp : member?.imageUrl}
        fallbackText={name}
        variant="image"
        size={avatar?.size || 20}
        {...avatar}
      />
    );
  }
};

export const MemberPhone = ({ memberId, height = 15 }: { memberId: string; height?: number }) => {
  const { data: member, status } = useMemberQuery({ memberId });

  return <Fragment />;

  // if (status === "pending")
  //   return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
  // if (status === "error") return "-";
  // return (
  //   <Fragment>
  //     {member?.phoneCode} {member?.phoneNumber}
  //   </Fragment>
  // );
};

export const MemberLocation = ({
  memberId,
  height = 15
}: {
  memberId: string;
  height?: number;
}) => {
  const { data: member, status } = useMemberQuery({ memberId });

  return <Fragment />;

  // if (status === "pending")
  //   return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
  // if (status === "error") return "-";
  // return (
  //   <div className="flex items-start mt-3">
  //     <IconLocation style={{ width: rem(22), height: rem(22) }} />
  //     {!(member.country && member.state && member.city) ? (
  //       <UnstyledButton>
  //         <Text>+ Add Location</Text>
  //       </UnstyledButton>
  //     ) : (
  //       <Text className="ms-2">
  //         {member.country}
  //         {member.state && `, ${member.state}`}
  //         {member.city && `, ${member.city}`}
  //       </Text>
  //     )}
  //   </div>
  // );
};

export const MemberSocial = ({ memberId, height = 15 }: { memberId: string; height?: number }) => {
  const { data: member, status } = useMemberQuery({ memberId });

  return <Fragment />;

  // if (status === "pending")
  //   return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
  // if (status === "error") return "-";
  // return <Fragment>{member?.socialLinks}</Fragment>;
};

export const MemberDisplayName = ({
  memberId,
  height = 15
}: {
  memberId: string;
  height?: number;
}) => {
  const { data: member, status } = useMemberQuery({ memberId });

  return <Fragment />;

  // if (status === "pending")
  //   return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
  // if (status === "error") return "-";
  // return <span className="text-sm text-gray-400">{member?.displayName}</span>;
};

export const MemberPronous = ({ memberId, height = 15 }: { memberId: string; height?: number }) => {
  const { data: member, status } = useMemberQuery({ memberId });

  return <Fragment />;

  // if (status === "pending")
  //   return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
  // if (status === "error") return "-";
  // return <span className="text-sm text-gray-400">{member?.pronouns}</span>;
};
